// Colors
$black: #131313;
$white: #ffffff;
$wild-watermelon: #ff5577;
$light-grey: #cccccc;
$grey88: #e0e0e0;
$whisper: #e9e9e9;

// size
$header-height: 70px;
$footer-height: 70px;
$padding-x-md: 10px;
$padding-x-xl: 40px;
