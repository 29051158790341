@import './../common/import';
// @import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/utilities';
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '@fortawesome/fontawesome-free/scss/solid';
// font-size class
@for $i from 10 through 40 {
  .fs#{$i} {
    font-size: ($i * 1px) !important;
  }
}

// max-width max-height class
@for $i from 1 through 20 {
  .maxw-#{$i * 50} {
    max-width: $i * 50px;
  }
  .maxh-#{$i * 50} {
    max-height: $i * 50px;
  }
}

// min-width class
@for $i from 1 through 20 {
  .minw-#{$i * 50} {
    min-width: $i * 50px;
  }
}

// width class
@for $i from 1 through 100 {
  .w-#{$i} {
    width: $i * 1%;
  }
  .vw-#{$i} {
    width: $i * 1vw;
  }
  .vh-#{$i} {
    height: $i * 1vh;
  }
  .min-vw-#{$i} {
    min-width: $i * 1vw;
  }
  .min-vh-#{$i} {
    min-height: $i * 1vh;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
  }
}

#client-page {
  .chat-box {
    position: relative;
  }

  .chat-box-container {
    cursor: pointer;
    right: 0;
    bottom: 0;
    position: fixed;
    float: right;
    width: 100%;
    height: 100%;
    .panel {
      height: 100vh;
    }
  }

  .d-list-item {
    display: list-item;
  }

  .panel-body {
    border-left: 1px solid $grey88;
  }

  @keyframes dotTyping {
    0% {
      box-shadow: 9984px 0 0 0 var(--customColor),
        9999px 0 0 0 var(--customColor), 10014px 0 0 0 var(--customColor);
    }
    16.667% {
      box-shadow: 9984px -10px 0 0 var(--customColor),
        9999px 0 0 0 var(--customColor), 10014px 0 0 0 var(--customColor);
    }
    33.333% {
      box-shadow: 9984px 0 0 0 var(--customColor),
        9999px 0 0 0 var(--customColor), 10014px 0 0 0 var(--customColor);
    }
    50% {
      box-shadow: 9984px 0 0 0 var(--customColor),
        9999px -10px 0 0 var(--customColor), 10014px 0 0 0 var(--customColor);
    }
    66.667% {
      box-shadow: 9984px 0 0 0 var(--customColor),
        9999px 0 0 0 var(--customColor), 10014px 0 0 0 var(--customColor);
    }
    83.333% {
      box-shadow: 9984px 0 0 0 var(--customColor),
        9999px 0 0 0 var(--customColor), 10014px -10px 0 0 var(--customColor);
    }
    100% {
      box-shadow: 9984px 0 0 0 var(--customColor),
        9999px 0 0 0 var(--customColor), 10014px 0 0 0 var(--customColor);
    }
  }

  .stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10px 0;
    margin: 0 -5%;
    overflow: hidden;

    .dot-typing {
      position: relative;
      left: -9999px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: var(--customColor);
      color: var(--customColor);
      box-shadow: 9984px 0 0 0 var(--customColor),
        9999px 0 0 0 var(--customColor), 10014px 0 0 0 var(--customColor);
      animation: dotTyping 1.5s infinite linear;
    }
  }
}
#client-page,
#preview_cb {
  // scrollbar
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: var(--customColor);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    //background: lighten(var(--customColor), 50%);
  }
}

.chatbox-icon-show:hover {
  transform: translateY(-1rem);
}

.chat-box-container {
  .panel {
    border-radius: 5px 5px 0 0;
    position: relative;
  }

  .panel-heading {
    border-radius: 5px 5px 0 0;
    background-color: var(--customColor);
    color: white;
    padding: 10px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    height: 120px;
    .fa {
      font-size: 24px;
    }
  }

  .panel-heading-responsive {
    height: 70px;
  }

  .panel-heading-preview {
    height: 70px;
  }

  .panel-title {
    width: calc(100% - 55px);
    position: absolute;
    top: 0;
    left: 0;
  }

  .panel-title-preview {
    width: calc(100% - 55px);
  }

  .avatar {
    .img-responsive {
      max-width: 45px;
      min-width: 45px;
      height: 45px;
      object-fit: cover;
    }
    .img-max {
      max-width: 120px;
      min-width: 120px;
      height: 120px;
      object-fit: cover;
      @media (max-width: 71px) {
        max-width: 70px !important;
        min-width: 70px !important;
        height: 70px !important;
      }
    }
  }
  .ellipsis-flex {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
  }

  .panel-body {
    font-size: 14px;
    border: 1px solid $grey88;
    background: $white;
    margin: 0;
    padding: 0 25px 20px 25px;
    height: calc(100% - #{$header-height});
    overflow-x: hidden;

    .msg-container {
      padding: 10px 0;
      overflow: hidden;
      display: flex;

      .msg-content {
        padding: 10px;
        box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
        max-width: 100%;
        width: calc(100% - 10px);
        border-radius: 3px;
        position: relative;

        &::after {
          z-index: 1;
          content: '';
          position: absolute;
          bottom: 10px;
          right: -15px;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-left: 10px solid $light-grey;
          border-bottom: 7px solid transparent;
          border-right: 5px solid transparent;
        }

        &::before {
          z-index: 2;
          content: '';
          position: absolute;
          bottom: 11px;
          right: -13px;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-left: 10px solid var(--customColor);
          border-bottom: 5px solid transparent;
          border-right: 3px solid transparent;
        }

        &.receive {
          background: $whisper;
          color: $black;

          .button-start {
            background-color: #cc1e1e;
            border-radius: 2rem;
            color: $white;
            text-align: center;
          }

          .question-list {
            overflow-wrap: anywhere;
            p {
              white-space: pre-wrap;
            }

            .tag-container {
              text-align: right;
              margin-top: 1.5rem;

              span {
                font-weight: 600;
                color: rgb(84, 84, 84);
                font-style: italic;
                gap: 0.5rem;
                background-color: $white;
                border-radius: 3px;
                padding: 2px 5px;
                text-align: right;
                border: 1px solid $light-grey;
                display: inline-block;

                &:hover {
                  background-color: #f5f5f5;
                }
              }
            }

            .btn-container {
              display: flex;
              flex-direction: column;
              margin-top: 1rem;
              align-items: flex-end;
              gap: 4px;

              span {
                font-weight: 600;
                color: rgb(84, 84, 84);
                background-color: $white;
                border-radius: 3px;
                padding: 2px 4px;
                text-align: right;
                border: 1px solid $light-grey;
                display: inline-block;
                border-radius: 12px;
                border-top-right-radius: 0;
                border-bottom-left-radius: 0;
                width: max-content;
                &:hover {
                  background-color: #f5f5f5;
                }
              }
            }

            .item-list {
              margin-top: -1rem;
              margin-bottom: -1rem;
              padding: 3px 0;
              list-style: none;
              &:not(.preview) {
                cursor: pointer;
                .item-content:hover {
                  background-color: $light-grey;
                  border: 1px solid $light-grey;
                }
              }

              .item-content {
                padding: 4px;
                background-color: $white;
                border: 1px solid $white;
                border-radius: 3px;
                display: block;
                font-size: 12px;
                margin: 4px 0;
                span {
                  overflow-wrap: anywhere;
                }
              }

              .list-pagination {
                .item-content {
                  display: none;
                }

                &:first-child .item-content,
                &:nth-child(2) .item-content,
                &:nth-child(3) .item-content,
                &:last-child .item-content {
                  display: block;
                }

                .dots-pagination {
                  text-align: center;

                  &:after {
                    content: '\2807';
                    font-size: 30px;
                  }
                }
              }
            }

            .button-bottom {
              border-radius: 2rem;
              display: block;
              border: none;
              color: $white;
              width: 100%;
              text-align: center;
              padding: 8px;
              margin-top: 0.5rem;
              outline: none;
            }

            .button-contact {
              background-color: #32b6b8;

              &:hover {
                background-color: #27a3b9;
              }
            }

            .button-start {
              background-color: #cc1e1e;
              cursor: pointer;

              &:hover {
                background-color: #b60909;
              }
            }

            .home-layer {
              border: none;
              background-color: #27a3b9;
              color: $white;
              padding: 0.5rem;
            }
          }
        }

        &.sent {
          background: var(--customColor);
          color: $white;
          overflow-wrap: anywhere;
        }
      }
    }

    .msg-receive {
      ::after {
        transform: rotate(-180deg);
        left: -15px;
        bottom: inherit;
        top: 10px;
      }

      ::before {
        transform: rotate(-180deg);
        right: inherit;
        left: -12px;
        bottom: inherit;
        border-left-color: $whisper !important;
        top: 12px;
      }
    }

    .msg-sent {
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
}

.fa-minus:before {
  content: '\f068';
}

a {
  transition: all ease 0.3s;
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: none !important;
  }
}

.expand-margin {
  margin-top: 2rem !important;
}

.disabled-pointer {
  pointer-events: none;
}
